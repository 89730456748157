.item {
  margin-bottom: 5px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  border: 1px solid #f4f4f4;
  padding: 5px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 3px,
    rgba(0, 0, 0, 0.117647) 0px 1px 2px;

  div .form-group {
    margin: 0;
  }
}

.actions {
  margin-right: 10px;

  button {
    margin-right: 3px;

    div {
      margin: 0;
      font-size: 15px;
    }
  }
}

.editor {
  width: 100%;
}
