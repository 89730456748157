.tether-element {
    z-index: 13000;
    .rdtPicker {
      width: 50;
        display: block;
        position: static;
    }
    .rdtPicker tr {
      height: 50px; 
    }
}
.tether-out-of-bounds-top .rdtPicker {
    visibility: hidden;
}