.dropzone-anexo {
    width: 100%;
  }
  
  .dropzone-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .dropzone-table thead tr {
    background-color: #fff;
  }
  
  .dropzone-table thead th {
    color: #4D4D4D;
    padding: 8px;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
  }
  
  .dropzone-table tbody tr:nth-child(odd) {
    background-color: #fff;
  }
  
  .dropzone-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .dropzone-table tbody td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
    color: #737373;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 9.375rem;
  }
  
  .dropzone-table tbody tr:last-child td {
    border-bottom: none;
  }
  
  .dropzone-table .new-row {
    background-color: #fff8e1 !important;
  }
  
  .text-center {
    text-align: center;
  }
  
  .tabela-hint {
    margin-left: 0.3rem;;
  }