* {
  box-sizing: border-box;
}

.item-arvore {
  display: flex;
  align-items: center;

  > .sub-itens {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 20px;
    transform: scaleY(0);
    height: 0;
    opacity: 0;
    transition:
      transform 1s ease,
      opacity 1s ease;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      bottom: 0;
      width: 20px;
      border-top: 1px solid #ccc;
    }
  }

  .item-arvore {
    position: relative;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    &:only-child {
      &::before {
        border-left: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-left: 1px solid #ccc;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      bottom: 0;
      width: 20px;
      border-top: 1px solid #ccc;
    }

    &:not(:only-child):first-child::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      bottom: 0;
      border-left: 1px solid #ccc;
    }

    &:not(:only-child):last-child::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 50%;
      top: 0;
      border-left: 1px solid #ccc;
    }
  }

  &.expandido {
    > .sub-itens {
      transform: scaleY(1);
      opacity: 1;
      height: auto;
    }
    > div[class*="-loadingContainer"] {
      height: auto;
    }
  }
}
