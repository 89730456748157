* {
  box-sizing: border-box;
}

.item-arvore-duplo {
  display: flex;
  align-items: center;

  .sub-itens-left, .sub-itens-right {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  > .sub-itens-left {    
    padding-right: 20px;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      bottom: 0;
      width: 20px;
      border-top: 1px solid #ccc;
    }
  }

  > .sub-itens-right {
    padding-left: 20px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      bottom: 0;
      width: 20px;
      border-top: 1px solid #ccc;
    }
  }

  .item-arvore-duplo, .item-arvore-duplo-left, .item-arvore-duplo-right {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;    

    &:only-child {
      &::before {
        border-left: none;
        border-right: none;
      }
    }    
  }  

  // LEFT
  
  .item-arvore-duplo-left {
    padding-right: 20px;
    justify-content: flex-end;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border-left: 1px solid #ccc;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      bottom: 0;
      width: 20px;
      border-top: 1px solid #ccc;
    }

    &:not(:only-child):first-child::before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      bottom: 0;
      border-left: 1px solid #ccc;
    }

    &:not(:only-child):last-child::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 50%;
      top: 0;
      border-left: 1px solid #ccc;
    }
  }

  // RIGHT

  .item-arvore-duplo-right {
    padding-left: 20px;
    justify-content: flex-start;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-left: 1px solid #ccc;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      bottom: 0;
      width: 20px;
      border-top: 1px solid #ccc;
    }

    &:not(:only-child):first-child::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      bottom: 0;
      border-left: 1px solid #ccc;
    }

    &:not(:only-child):last-child::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 50%;
      top: 0;
      border-left: 1px solid #ccc;
    }
  }
}
